import {BASE_URL} from "../util/constants";
import axios from 'axios';

const THIRD_PARTY_ENDPOINT = `${BASE_URL}/api/third-party/`;


const thirdPartyController = {

    getQuotationData: async (encodedJwt, language) => {
        try {
            const response = await axios.get(`${THIRD_PARTY_ENDPOINT}quotation`,
                {params: {token: encodedJwt, language}}
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    postQuotationCosts: async (encodedJwt, language, data, reference) => {
        try {
            const response = await axios.post(`${THIRD_PARTY_ENDPOINT}quotation`,
                {costData: data, thirdPartyReference: reference},
                {params: {token: encodedJwt, language}}
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
}

export default thirdPartyController;