import controller from "../../controller/controller";
import {notifications} from "@mantine/notifications";
import TextInput from "../base/TextInput/TextInput";
import UnlocoTable from "../UnlocoTable";
import React from "react";
import Text from "../base/Text/Text";
import Container from "../base/Container/Container";

/**
 * @param label {string} - Label for the input and the table | "Origen" | "Destino"
 * @param unloco {object} - State with the unloco code and name: {code: "", name: ""}
 * @param setUnloco {function} - Function to set the unloco state
 * */
export default function UnlocoAsyncInput({label, unloco, setUnloco, error, errorMsg, runOnBlur = null, ...props}) {

    function handleChange(e) {
        const {name, value} = e.target;
        setUnloco((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    async function handleEventChange(event) {
        if (runOnBlur) runOnBlur();
        /* allow the user to clear the fields if input was empty and an event is triggered */
        if (unloco?.code === "") {
            setUnloco({code: "", name: ""});
            return;
        }

        if (event.key === "Tab" || event.key === "Enter" || event.type === "blur") {
            try {
                const data = await controller.getUnloco({code: unloco?.code});
                if (data.length === 0) {
                    setUnloco({code: "", name: "INVALIDO"});
                } else {
                    setUnloco({code: data[0].codigo, name: data[0].nombre});
                }
            } catch (error) {
                notifications.show({
                    title: "Error!",
                    message: "Error al buscar UNLOCO.",
                    color: "red",
                });
            }
        }
    }

    return (
        <Container styleVariant={"unstyled"} direction={"row"} wrap={"wrap"} columnGap={8} align={"start"}>
            <Text lh={1.55} mb={1} w={"100%"} cVariant={"primary"}>{label}</Text>
            <TextInput
                name={"code"}
                value={unloco?.code} onChange={handleChange}
                onKeyDown={handleEventChange}
                onBlur={handleEventChange}
                style={{width: "22.5%"}}
                error={error ?? false}
                {...props}
            />
            <TextInput
                value={unloco?.name}
                style={{width: "60%"}}
                disabled
                error={error ?? false}
                {...props}
            />
            <UnlocoTable label={label}
                         setUnloco={setUnloco}/>
            {error &&
                <Text fs-variant={"xxs"} fw={400} mt={"4px"} w={"100%"} cVariant={"error"}>{errorMsg}</Text>
            }
        </Container>
    )
}