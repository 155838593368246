import React, {useContext, useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {NOTES_TYPES, TRANSLATION_KEYS} from "../util/constants";
import {Textarea} from "@mantine/core";
import {LanguageContext} from "../context/LanguageContext";
import Button from "./base/Button/Button";
import Title from "./base/Title/Title";
import {NOTES_INITIAL_STATE} from "../models/models";
import {addOrUpdateNote, getNoteValueByType} from "../util/functions";

const WorkNotes = ({updateStateValues, workNotes, onClose, readOnly = false}) => {
    const {t} = useContext(LanguageContext);
    const [note, setNote] = useState(NOTES_INITIAL_STATE.ON_REQUEST)

    useEffect(() => {
        setNote({...note, value: getNoteValueByType(workNotes, NOTES_TYPES.ON_REQUEST)})
    }, [])

    const handleOnClose = () => {
        if (!readOnly) {
            const updatedNotes = addOrUpdateNote(workNotes, note)
            updateStateValues({notes: updatedNotes});
        }

        onClose()
    }

    return (
        <Modal size="lg" show={true} onHide={handleOnClose} backdrop="static" keyboard={false} nested={0}>
            <Modal.Header closeButton style={{height: "50px"}}>
                <Title>{t(TRANSLATION_KEYS.NOTES)}</Title>
            </Modal.Header>
            <Modal.Body>
                <Textarea rows={8} defaultValue={note.value} placeholder="Ingrese las notas de trabajo"
                          style={{resize: "none"}}
                          autoComplete="on" onChange={(e) => setNote({...note, value: e.target.value})}
                          disabled={readOnly}/>
            </Modal.Body>
            <Modal.Footer>
                <Button id="save-close-button" onClick={handleOnClose}>
                    {t(TRANSLATION_KEYS.CLOSE)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default WorkNotes;