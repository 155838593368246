import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ContainerTable from '../../../components/ContainerTable.jsx';
import PickupTable from "../../../components/PickupTable.jsx";
import PackingTable from "../../../components/PackingTable.jsx";
import ProvidersTable from "./components/ProvidersTable.jsx";
import UploadDocs from "../../../components/UploadDocs.jsx";
import WorkNotes from "../../../components/WorkNotes.jsx";
import {
    CURRENCIES_OPTIONS,
    DRAFT_TYPES,
    INCOTERM_OPTIONS, NOTES_TYPES, STACKABLE_OPTIONS, STACKABLE_TYPES,
    TRANSPORT_OPTIONS
} from '../../../util/constants.js';
import {NEW_QUOTATION_VALIDATOR, QUOTATION_INITIAL_STATE} from "../../../models/models";
import providerController from "../../../controller/providerController.js";
import quotationController from "../../../controller/quotationController.js";
import userController from "../../../controller/userController.js";
import {Indicator, rem} from "@mantine/core";
import {IconArrowLeft} from '@tabler/icons-react';
import RichTextEditor from "../../../components/RichTextEditor";
import OrganizationAsyncSelect from "../../../components/async_select/OrganizationAsyncSelect";
import {notifications} from "@mantine/notifications";
import {modals} from "@mantine/modals";
import {getAuth, isStringEmpty, noteExistsAndIsNotEmpty} from "../../../util/functions";
import SimpleAddProvidersModal from "./components/SimpleAddProvidersModal";
import _ from "lodash";
import Container from "../../../components/base/Container/Container";
import Button from "../../../components/base/Button/Button";
import Title from "../../../components/base/Title/Title";
import Select from "../../../components/base/Select/Select";
import TextInput from "../../../components/base/TextInput/TextInput";
import CommodityAsyncInput from "../../../components/async_input/CommodityAsyncInput";
import UnlocoAsyncInput from "../../../components/async_input/UnlocoAsyncInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faHourglassHalf} from "@fortawesome/free-solid-svg-icons";
import Text from "../../../components/base/Text/Text";
import {LoadingContext} from "../../../context/LoadingContext";
import SaveDraftModal from "../../../components/SaveDraftModal";
import DatePickerInput from "../../../components/base/DatePickerInput/DatePickerInput";
import {STORAGE} from "../../../util/localStorage";
import Checkbox from "../../../components/base/Checkbox/Checkbox";
import Menu from "../../../components/base/Menu/Menu";
import {MenuTarget} from "../../../components/base/Menu/MenuTarget";
import MenuDropdown from "../../../components/base/Menu/MenuDropdown";
import MenuItem from "../../../components/base/Menu/MenuItem";

function QuotationAgents() {
    const {setLoading} = useContext(LoadingContext);
    const navigate = useNavigate();
    const location = useLocation();
    const {ref} = useParams();
    const [isNewQuotation, setIsNewQuotation] = useState(true);
    const [state, setState] = useState(QUOTATION_INITIAL_STATE);
    const [gettingData, setGettingData] = useState(false);
    const [mail, setMail] = useState('');
    const [defaultMail, setDefaultMail] = useState('');
    const [mailSubject, setMailSubject] = useState('');
    const [quotationContacts, setQuotationContacts] = useState([]);
    const [mailIdioma, setMailIdioma] = useState('es');
    const [modeOptions, setModeOptions] = useState([]);
    const [showUploadDocs, setShowUploadDocs] = useState(false);
    const [comerciales, setComerciales] = useState([]);
    const [showWorkNotes, setShowWorkNotes] = useState(false);
    const [insurance, setInsurance] = useState(false);
    const [editableKgAndCbm, setEditableKgAndCbm] = useState(true);
    const [containerVisible, setContainerVisible] = useState(false);
    const [pickupVisible, setPickupVisible] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [addingDirectCosts, setAddingDirectCosts] = useState(false);
    const [availableProviders, setAvailableProviders] = useState([]);
    const [selectedCommodity, setSelectedCommodity] = useState(null);
    const [selectedOriginUnloco, setSelectedOriginUnloco] = useState({code: '', name: ''});
    const [selectedDestinationUnloco, setSelectedDestinationUnloco] = useState({code: '', name: ''});
    const [notifyCommercial, setNotifyCommercial] = useState(false);
    const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
    const [draft, setDraft] = useState(location?.state?.draft ? location?.state?.draft : null);
    const [intermediateDraftState, setIntermediateDraftState] = useState(null);
    const [invalidFields, setInvalidFields] = useState(NEW_QUOTATION_VALIDATOR);

    useEffect(() => {
        if (state.transportMode &&
            state.modo &&
            state.incoterm &&
            state.origenCodigo &&
            state.destinoCodigo &&
            state.commodityCodigo &&
            state.commercial
        ) {
            setDefaultMail(generateMail(state, mailIdioma));
            setMailSubject(generateSubject(mailIdioma));
        }
    }, [state, mailIdioma, comerciales])

    useEffect(() => {
        if (draft !== null) {
            loadDraft();
        } else if (ref) {
            getData();
        } else {
            setState(QUOTATION_INITIAL_STATE);
            setInsurance(false);
            setModeOptions([]);
            setMailIdioma('es');
            setMail('');
            setContainerVisible(false);
            setPickupVisible(false);

            setInsurance(false);
        }

        userController.getCommercials().then((comercialData) => {
            setComerciales(comercialData);
        });
    }, [ref]);

    useEffect(() => {
        if (location?.state?.isNewQuotation !== undefined && location?.state?.isNewQuotation !== null) {
            setIsNewQuotation(location?.state?.isNewQuotation);
        } else {
            setIsNewQuotation(true);
        }
    }, [location?.state]);

    useEffect(() => {
        if (insurance) {
            setState({...state, valorSeguro: state.valorMercaderia, monedaSeguro: state.monedaMercaderia})
        } else {
            setState({...state, valorSeguro: '', monedaSeguro: ''})
        }
    }, [state.valorMercaderia, insurance])

    useEffect(() => {
        let totalChargeable = 0;
        if (state.transportMode !== '' && state.cbmTotal !== '' && state.pesoTotal !== '') {
            if (state.transportMode === 'AIR') {
                totalChargeable = parseFloat(state.cbmTotal) * 166.666666667;
            } else if (state.transportMode === 'SEA') {
                totalChargeable = parseFloat(state.cbmTotal) * 1000;
            } else if (state.transportMode === 'ROAD') {
                totalChargeable = parseFloat(state.cbmTotal) * 333.333333333;
            }

            if (totalChargeable < state.pesoTotal) {
                totalChargeable = parseFloat(state.pesoTotal);
            }
            if (!isNaN(totalChargeable)) {
                setState({...state, chargeableTotal: Number(totalChargeable.toFixed(3))});
            }
        } else {
            setState({...state, chargeableTotal: ''});
        }
    }, [state.cbmTotal, state.pesoTotal, state.transportMode])

    useEffect(() => {
        if (state.packingData.length > 0) {
            setEditableKgAndCbm(false);
            setInvalidFields((prevState) => ({
                ...prevState,
                cbmTotal: {isInvalid: false, message: ''},
                pesoTotal: {isInvalid: false, message: ''},
            }));
        } else {
            setEditableKgAndCbm(true);
        }
    }, [state.packingData])

    useEffect(() => {
        if (selectedClient) {
            setState((prevState) => ({
                ...prevState,
                client: selectedClient,
            }));
        }
    }, [selectedClient])

    useEffect(() => {
        if (selectedCommodity) {
            setState((prevState) => ({
                ...prevState,
                commodityCodigo: selectedCommodity.code,
                commodityDescr: selectedCommodity.description,
            }));
        }
    }, [selectedCommodity]);

    useEffect(() => {
        if (selectedOriginUnloco) {
            setState((prevState) => ({
                ...prevState,
                origenCodigo: selectedOriginUnloco.code,
                origenName: selectedOriginUnloco.name,
            }));
        }
    }, [selectedOriginUnloco]);

    useEffect(() => {
        if (selectedDestinationUnloco) {
            setState((prevState) => ({
                ...prevState,
                destinoCodigo: selectedDestinationUnloco.code,
                destinoName: selectedDestinationUnloco.name,
            }));
        }
    }, [selectedDestinationUnloco]);

    useEffect(() => {
        findProviders();
    }, [state.origenCodigo, state.destinoCodigo, state.transportMode, state.modo]);

    async function getData() {
        if (gettingData) return;
        if (!ref) return;

        setLoading(true);
        setGettingData(true);

        try {
            const quotation = await quotationController.getQuotation(ref).then((data) => data.data);

            if (quotation.transportMode === "SEA") {
                setModeOptions(TRANSPORT_OPTIONS.SEA)
            } else if (quotation.transportMode === "AIR") {
                setModeOptions(TRANSPORT_OPTIONS.AIR)
            } else if (quotation.transportMode === "ROAD") {
                setModeOptions(TRANSPORT_OPTIONS.ROAD)
            }

            if (quotation.modo === "FCL" || quotation.modo === "FTL") {
                setContainerVisible(true);
            }

            if (quotation?.pickupData?.length > 0) {
                setPickupVisible(true);
            }

            if (quotation.valorSeguro) {
                setInsurance(true);
            } else {
                setInsurance(false);
            }

            setSelectedCommodity({code: quotation.commodityCodigo, description: quotation.commodityDescr});
            setSelectedOriginUnloco({code: quotation.origenCodigo, name: quotation.origenName});
            setSelectedDestinationUnloco({code: quotation.destinoCodigo, name: quotation.destinoName});
            setSelectedClient({id: quotation.client.id, nombreFantasia: quotation.client.nombreFantasia});

            const newQuotationState = {
                ...quotation,
                agentData: [],
                contacts: [],
                docs: quotation.docs.filter(doc => !doc.nombre.startsWith("cost")),
                cost: [],
                sell: [],
                valorSeguro: '',
                monedaSeguro: '',
            }
            setState(newQuotationState);
        } catch (error) {
            notifications.show({
                title: "Error!",
                message: "Error al obtener la cotización.",
                color: "red",
            });
        }
        setLoading(false);
        setGettingData(false);
    }

    function loadDraft() {
        if (gettingData) return;

        setLoading(true);
        setGettingData(true);

        try {
            const quotation = draft.data;

            if (quotation.transportMode === "SEA") {
                setModeOptions(TRANSPORT_OPTIONS.SEA)
            } else if (quotation.transportMode === "AIR") {
                setModeOptions(TRANSPORT_OPTIONS.AIR)
            } else if (quotation.transportMode === "ROAD") {
                setModeOptions(TRANSPORT_OPTIONS.ROAD)
            }

            if (quotation.modo === "FCL" || quotation.modo === "FTL") {
                setContainerVisible(true);
            }

            if (quotation?.pickupData?.length > 0) {
                setPickupVisible(true);
            }

            if (quotation.valorSeguro) {
                setInsurance(true);
            } else {
                setInsurance(false);
            }

            setSelectedCommodity({code: quotation.commodityCodigo, description: quotation.commodityDescr});
            setSelectedOriginUnloco({code: quotation.origenCodigo, name: quotation.origenName});
            setSelectedDestinationUnloco({code: quotation.destinoCodigo, name: quotation.destinoName});
            setSelectedClient({id: quotation.client.id, nombreFantasia: quotation.client.nombreFantasia});

            setIntermediateDraftState({
                ...quotation,
                agentData: [],
                contacts: [],
                cost: [],
                sell: [],
                valorSeguro: '',
                monedaSeguro: '',
            });

            notifications.show({
                title: "Borrador cargado!",
                message: `Se han cargado los datos del borrador ${draft?.name} correctamente.`,
                color: "green",
            });
        } catch (error) {
            notifications.show({
                title: "Error!",
                message: "Error al cargar el borrador.",
                color: "red",
            });
        }
        setLoading(false);
        setGettingData(false);
    }

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            ...intermediateDraftState
        }));
    }, [intermediateDraftState]);

    function handleChangeByName(name, value) {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function handleTransportModeChange(name, value) {
        const transportMode = value;
        let options = [];

        setContainerVisible(false);
        if (transportMode === "SEA") {
            options = TRANSPORT_OPTIONS.SEA;
        } else if (transportMode === "AIR") {
            options = TRANSPORT_OPTIONS.AIR;
        } else if (transportMode === "ROAD") {
            options = TRANSPORT_OPTIONS.ROAD;
        }

        setState((prevState) => ({
            ...prevState,
            [name]: value,
            modo: "",
        }));

        setModeOptions(options);
    }

    function handleModoChange(value) {
        if (value === "FCL" || value === "FTL") {
            setContainerVisible(true);
        } else {
            setContainerVisible(false);
            state.containerData = [];
            state.containerQty = "";
        }

        setState((prevState) => ({
            ...prevState,
            modo: value,
        }));
    }

    function handleIncotermChange(incoterm) {
        if (incoterm === "EXW" || incoterm === "FCA") {
            setPickupVisible(true);
        } else {
            setPickupVisible(false);
            state.pickupData = [];
        }
        setState((prevState) => ({
            ...prevState,
            incoterm: incoterm,
        }));
    }

    function handleChangeNumeric(event) {
        const {name, value} = event.target;
        const re = /^[0-9\b.]+$/;

        if (value === '' || re.test(value)) {
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    }

    function handleInsuranceChange(value) {
        setInsurance(value === "YES");
    }

    function handleClientChange(client) {
        setSelectedClient(prevState => ({
            ...prevState,
            ...client,
        }));
    }

    function handleClientChangeByName(name, value) {
        setSelectedClient(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    function stateValues(values) {
        setState(prevState => ({
            ...prevState,
            ...values,
        }));
    }

    async function findProviders() {
        setAvailableProviders([])
        if (!necessaryDataForProvider()) return;

        try {
            const providerFilter = {
                originCode: state.origenCodigo,
                destinationCode: state.destinoCodigo,
                transportMode: state.transportMode,
                mode: state.modo,
            }

            const data = await providerController.getProvidersForQuotation(providerFilter);
            setAvailableProviders(data);
        } catch (error) {
            notifications.show({
                title: "Error!",
                message: "Error al obtener proveedores.",
                color: "red",
            });
        }
    }

    function generateMail(state, language) {
        const translations = {
            en: {
                contacto: 'Dear ',
                salutation: '<br>Good day,<br><br>Can you please help us quote this @mode@ shipment?',
                url_request: 'Please, if possible, complete your costs using the link at the end of the request.',
                transporte: 'Transport',
                modo: 'Mode',
                contenedor: 'Containers',
                incoterm: 'INCOTERM',
                origen: 'Origin',
                destino: 'Destination',
                pol: 'POL',
                pod: 'POD',
                aol: 'AOL',
                aod: 'AOD',
                shipmentDate: 'Shipment Date',
                valor: 'Value',
                kg: 'Total Weight',
                cbm: 'Total Volume',
                chargeable: 'Chargeable',
                stackable: 'Stackable',
                commodity: 'Commodity',
                unidades: 'Quantity of units',
                packing: 'Packing',
                pickups: 'Pickup',
                valorAsegurado: 'Insured Value',
                saludo: 'Regards',
                yes: 'Yes',
                no: 'No',
                url: 'Please DO NOT reply to this email, upload your quote ',
                url_a: 'HERE',
                shipper: 'Shipper',
                order: 'Order',
                air: 'air',
                of: 'of',
            },
            es: {
                contacto: '',
                salutation: ' como estás?<br><br>Podrás ayudarnos con la siguiente cotización @mode@ por favor?',
                url_request: 'Por favor en lo posible completar sus costos en el link que figura al final de la solicitud.',
                transporte: 'Transporte',
                modo: 'Modo',
                contenedor: 'Contenedores',
                incoterm: 'INCOTERM',
                origen: 'Origen',
                destino: 'Destino',
                pol: 'POL',
                pod: 'POD',
                aol: 'AOL',
                aod: 'AOD',
                shipmentDate: 'Fecha de Embarque',
                valor: 'Valor',
                kg: 'Peso Total',
                cbm: 'Volumen Total',
                chargeable: 'Chargeable',
                stackable: 'Apilable',
                commodity: 'Commodity',
                unidades: 'Cantidad de unidades',
                packing: 'Packing',
                pickups: 'Pickup',
                valorAsegurado: 'Valor Asegurado',
                saludo: 'Saludos',
                yes: 'Si',
                no: 'No',
                url: 'Por favor NO responda este correo, cargue su cotizaci\u00F3n ',
                url_a: 'AQUI',
                shipper: 'Shipper',
                order: 'Orden',
                air: 'aérea',
                of: 'de',
            },
            pt: {
                contacto: '',
                salutation: ' como estás?<br><br>Podrás ayudarnos con la siguiente cotización @mode@ por favor?',
                url_request: 'Por favor en lo posible completar sus costos en el link que figura al final de la solicitud.',
                transporte: 'Transporte',
                modo: 'Modo',
                contenedor: 'Contêiner',
                incoterm: 'INCOTERM',
                origen: 'Origem',
                destino: 'Destino',
                pol: 'POL',
                pod: 'POD',
                aol: 'AOL',
                aod: 'AOD',
                shipmentDate: 'Data de Embarque',
                valor: 'Valor',
                kg: 'Peso Total',
                cbm: 'CBM Total',
                chargeable: 'Chargeable',
                stackable: 'Empilhável',
                commodity: 'Commodity',
                unidades: 'Unidades',
                packing: 'Detalhamento da embalagem',
                pickups: 'Pickups',
                valorAsegurado: 'Valor Segurado',
                saludo: 'Atenciosamente',
                yes: 'Sim',
                no: 'Não',
                url: 'Por favor, N\u00C3O responda a este e-mail, carregue sua cota\u00E7\u00E3o ',
                url_a: 'AQUI',
                shipper: 'Shipper',
                order: 'Ordem',
                air: 'aérea',
                of: 'de',
            },
        };

        const translation = translations[language];
        let string = ``;

        string = `${translation.contacto}@contact@,`;

        const mode = state.transportMode === 'AIR' ? translation.air : state.modo;
        string += `${translation.salutation.replace("@mode@", mode)}<br>`
        string += `${translation.url_request}<br><br>`;

        string += `${translation.incoterm}: ${state.incoterm}<br>`;
        if (state.pickupData?.length > 0) {
            string += `${translation.origen}: `;
            let pickupCount = 1;
            state.pickupData.forEach((pickup) => {
                const {direccion, codPostal, ciudad, estado, proveedor, nroOrden, volumen, peso} = pickup;
                if (state.pickupData.length > 1) {
                    string += `
                        <br>PICKUP ${pickupCount} - ${direccion}, ${codPostal}, ${ciudad}, ${estado}<br>
                        ${translation.shipper}: ${proveedor}, ${translation.order}: ${nroOrden}, ${volumen}m3, ${peso}kg<br>
                    `;
                    pickupCount++;
                } else {
                    string += `
                        ${direccion}, ${codPostal}, ${ciudad}, ${estado}<br>
                        ${translation.shipper}: ${proveedor}, ${translation.order}: ${nroOrden}<br>
                    `;
                }
            });
            string += `<br>`;
        }
        string += `${state.transportMode === 'AIR' ? translation.aol : translation.pol}: ${state.origenName}<br>`;
        string += `${state.transportMode === 'AIR' ? translation.aod : translation.pod}: ${state.destinoName}<br><br>`;

        if (state.shipmentDate) {
            string += `${translation.shipmentDate}: ${new Date(state.shipmentDate)?.toISOString().split('T')[0]}<br>`;
        }

        if (state.valorMercaderia && state.valorMercaderia > 2) {
            string += `${translation.valor}: ${state.valorMercaderia} ${state.monedaMercaderia}<br>`;
        }

        string += `${translation.commodity}: ${state.commodityDescr}<br>`;

        if (state.pesoTotal > 0 || state.cbmTotal > 0) {
            string += `${translation.kg}: ${state.pesoTotal}kg <br> ${translation.cbm}: ${state.cbmTotal}m3`;
            string += state.transportMode === 'AIR' ? `<br> ${translation.chargeable}: ${state.chargeableTotal}kg<br>` : '';
        }
        string += `<br>`;

        let pesoMeasureSystem = 'kgs';
        let volumenMeasureSystem = 'mts';
        if (state.measureSystem === 'imperial') {
            pesoMeasureSystem = 'lbs';
            volumenMeasureSystem = 'in';
        }

        if (state.modo === 'FCL' && state.containerQty && state.containerData) {
            string += `<br>${translation.contenedor}: ${state.containerQty}<br>`;
            state.containerData.forEach((row) => {
                const {quantity, container} = row;
                string += `- ${container} x ${quantity}<br>`;
            });
            string += `<br>`;
        }

        if (state.modo === 'LCL') {
            let stackableText = "";
            switch (state.stackable) {
                case STACKABLE_TYPES.YES:
                    stackableText = translation.yes;
                    break;
                case STACKABLE_TYPES.NO:
                    stackableText = translation.no;
                    break;
                case STACKABLE_TYPES.TOP_LOAD:
                    stackableText = 'Top Load';
                    break;
            }
            string += `<br>${translation.stackable}: ${stackableText}<br>`;
        }

        if (state.packingData?.length > 0) {
            string += `${translation.packing}:<br>`;
            state.packingData.forEach((pack) => {
                const {ctnPlt, cantidad, kg, largo, ancho, alto} = pack;
                string += `- ${cantidad} ${ctnPlt} ${translation.of} ${kg}${pesoMeasureSystem} ${largo} x ${ancho} x ${alto} ${volumenMeasureSystem}<br>`;
            });
            string += `<br>`;
        }

        string += `<b>${translation.url} <a href="@url@">${translation.url_a}</a>.</b><br><br>`;

        string += `${translation.saludo},<br>`;
        string += `${getAuth()[4]?.split(' ')[0]}.`;


        return string;
    }

    function generateSubject(language) {
        const translations = {
            en: {
                subject: 'NEW QUOTE',
            },
            es: {
                subject: 'SOLICITUD DE COTIZACIÓN',
            },
            pt: {
                subject: 'NEW QUOTE',
            },
        };

        return translations[language].subject + ' // ' + state.origenCodigo.slice(2, 5) + '-' + state.destinoCodigo.slice(2, 5);
    }

    async function sendQuotation(sendEmail) {
        setInvalidFields(NEW_QUOTATION_VALIDATOR);
        try {
            let errorMessage = '';

            if (!state.cbmTotal && state.modo !== "FCL" && state.modo !== "FTL") {
                const noVolume = "Debe ingresar volumen total.";

                setInvalidFields((prevState) => ({...prevState, cbmTotal: {isInvalid: true, message: noVolume}}))
                errorMessage = noVolume;
            }

            if (!state.pesoTotal && state.modo !== "FCL" && state.modo !== "FTL") {
                const noWeight = "Debe ingresar peso total.";

                setInvalidFields((prevState) => ({...prevState, pesoTotal: {isInvalid: true, message: noWeight}}))
                errorMessage = noWeight;
            }

            if (state.modo === "FTL" && state.containerData.length === 0) {
                const noTruck = "Debe ingresar al menos un camión.";

                setInvalidFields((prevState) => ({...prevState, containerData: {isInvalid: true, message: noTruck}}))
                errorMessage = noTruck;
            }

            if (state.incoterm === "EXW" && state.pickupData.length === 0) {
                const noPickup = "Debe ingresar al menos un pickup.";

                setInvalidFields((prevState) => ({...prevState, pickupData: {isInvalid: true, message: noPickup}}))
                errorMessage = noPickup;
            }

            if (state.modo === "FCL" && state.containerData.length === 0) {
                const noContainer = "Debe ingresar al menos un contenedor.";

                setInvalidFields((prevState) => ({...prevState, containerData: {isInvalid: true, message: noContainer}}))
                errorMessage = noContainer;
            }

            if (isStringEmpty(state.commodityCodigo)) {
                const noCommodity = "Debe seleccionar un commodity.";

                setInvalidFields((prevState) => ({...prevState, commodity: {isInvalid: true, message: noCommodity}}))
                errorMessage = noCommodity;
            }

            if (isStringEmpty(state.incoterm)) {
                const noIncoterm = "Debe seleccionar un incoterm.";

                setInvalidFields((prevState) => ({...prevState, incoterm: {isInvalid: true, message: noIncoterm}}))
                errorMessage = noIncoterm;
            }

            if (isStringEmpty(state.destinoCodigo)) {
                const noDestination = "Debe seleccionar un destino.";
                setInvalidFields((prevState) => ({...prevState, destination: {isInvalid: true, message: noDestination}}))

                errorMessage = noDestination
            }

            if (isStringEmpty(state.origenCodigo)) {
                const noOrigin = "Debe seleccionar un origen.";

                setInvalidFields((prevState) => ({...prevState, origin: {isInvalid: true, message: noOrigin}}))
                errorMessage = noOrigin;
            }

            if (isStringEmpty(state.modo)) {
                const noModeError = "Debe seleccionar un modo.";

                setInvalidFields((prevState) => ({...prevState, mode: {isInvalid: true, message: noModeError}}))
                errorMessage = noModeError;
            }


            if (isStringEmpty(state.transportMode)) {
                const noTransportModeError = "Debe seleccionar un modo de transporte.";

                setInvalidFields((prevState) => ({...prevState, transportMode: {isInvalid: true, message: noTransportModeError}}))
                errorMessage = noTransportModeError;
            }

            if (!state.client.nombreFantasia) {
                const noClient = "Debe seleccionar un cliente.";

                setInvalidFields((prevState) => ({...prevState, client: {isInvalid: true, message: noClient}}))
                errorMessage = noClient;
            }

            if (!state.commercial && isNewQuotation) {
                const noCommercial = "Debe seleccionar un comercial.";

                setInvalidFields((prevState) => ({...prevState, commercial: {isInvalid: true, message: noCommercial}}))
                errorMessage = noCommercial;
            }

            if (errorMessage) {
                throw new Error(errorMessage);
            }


            if (sendEmail) {
                // mail is being sent to agents, then we validate contacts and mail data
                if (!quotationContacts || quotationContacts.length === 0) {
                    throw new Error('Debe de seleccionar al menos 1 contacto!');
                }

                if (!mailSubject) {
                    throw new Error('Debe de ingresar un asunto de mail!');
                }

                if (!mail && !defaultMail) {
                    throw new Error('Debe de ingresar un cuerpo de mail!');
                }

                if (!mail) {
                    state.mail = defaultMail.replaceAll(/<p>/g, '').replaceAll(/<\/p>/g, '<br>');
                } else {
                    state.mail = mail.replaceAll(/<p>/g, '').replaceAll(/<\/p>/g, '<br>');
                }

                state.mailSubject = mailSubject;
                state.contacts = quotationContacts;
            }

            setLoading(true);

            let response;
            let successMessage;

            if (!isNewQuotation) {
                await quotationController.sendProviderRequest({data: state}, sendEmail, notifyCommercial);
                successMessage = sendEmail ?
                    'Solicitud de cotización enviada correctamente!'
                    :
                    'Cotización guardada correctamente!';
            } else {
                response = await quotationController.newQuotationPricing({data: state}, sendEmail, notifyCommercial);
                successMessage = sendEmail ?
                    'Solicitud de cotización enviada correctamente!\nReferencia: ' + response
                    :
                    'Cotización guardada correctamente!\nReferencia: ' + response;
            }

            setLoading(false);

            if (draft !== null) {
                STORAGE.deleteDraftById(draft.id);
            }

            notifications.show({
                title: !state?.agentData ? "Solicitud de cotización enviada!" : "Cotización guardada!",
                message: successMessage,
                color: "green",
                autoClose: false
            });

        } catch (error) {
            let alertMessage;
            if (error?.message?.toLowerCase().includes("debe")) {
                alertMessage = error.message;
            } else {
                alertMessage = 'Error al enviar solicitud de cotización!';
            }

            setLoading(false);
            notifications.show({
                title: "Error!",
                message: alertMessage,
                color: "red",
            });
        }
    }

    function handleAddProviders(providers) {
        setAddingDirectCosts(false);
        state.agentData = providers;
        modals.openContextModal({
            modal: "confirm",
            title: "¿Guardar Cotización?",
            innerProps: {
                onConfirm: () => sendQuotation(false),
            }
        });
    }

    function handleAddDirectCosts() {
        try {
            if (!state.transportMode) {
                throw new Error('Debe seleccionar un transporte.');
            }
            if (!state.modo) {
                throw new Error('Debe seleccionar un modo.');
            }
            if (!state.origenCodigo) {
                throw new Error('Debe seleccionar un origen.');
            }
            if (!state.destinoCodigo) {
                throw new Error('Debe seleccionar un origen.');
            }

            setAddingDirectCosts(true);

        } catch (error) {
            notifications.show({
                title: "Error!",
                message: error.message,
                color: "red",
            });
        }

    }

    const necessaryDataForProvider = () => {
        // transportMode and modo needs to have a value
        // origenCodigo and destinoCodigo need to have a length === 5
        return state.transportMode && state.modo && state.origenCodigo.length === 5 && state.destinoCodigo.length === 5;
    }

    function handlePickupChange(data) {
        setState((prevState) => ({
            ...prevState,
            pickupData: data,
        }));
    }

    function handleDraft() {
        setShowSaveDraftModal(false);
        setDraft(null);
        setIntermediateDraftState(null);
        setState(QUOTATION_INITIAL_STATE);
    }

    return (
        <>
            {showUploadDocs &&
                <UploadDocs onClose={() => setShowUploadDocs(false)}
                            docs={state.docs}
                            updateStateValues={stateValues}
                />
            }
            {showWorkNotes &&
                <WorkNotes onClose={() => setShowWorkNotes(false)}
                           workNotes={state.notes}
                           updateStateValues={stateValues}
                />
            }
            {showSaveDraftModal &&
                <SaveDraftModal onClose={() => setShowSaveDraftModal(false)}
                                onSuccess={handleDraft}
                                data={state}
                                type={DRAFT_TYPES.NEW_PRICING}
                />
            }
            {addingDirectCosts &&
                <SimpleAddProvidersModal handleClose={() => setAddingDirectCosts(false)}
                                         origin={state.origenCodigo}
                                         destination={state.destinoCodigo}
                                         transportMode={state.transportMode}
                                         mode={state.modo}
                                         setQuotationProviders={handleAddProviders}
                />
            }
            {/* main container */}
            <Container styleVariant={"unstyled"} direction={"row"} p={8} columnGap={8} style={{
                minHeight: `calc(100vh - ${rem(73)})`
            }}>
                {/* left container */}
                <Container styleVariant={"unstyled"} direction={"column"} rowGap={8} w={"60%"}>
                    {/* info container */}
                    <Container pt={8}>
                        <div style={{display: "flex", flexWrap: "wrap", textAlign: "left"}}>
                            {!isNewQuotation ? (
                                <div style={{flexBasis: "100%", padding: "0 10px", boxSizing: "border-box"}}>
                                    <div className={"d-flex align-items-center mb-2"}>
                                        <Button onClick={() => navigate(-1)} leftSection={<IconArrowLeft size={14}/>}>
                                            Volver
                                        </Button>
                                        <div style={{flex: 1}}>
                                            <Title cVariant={"primary"} style={{textAlign: "center"}}>
                                                {ref}
                                            </Title>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{width: '100%', display: 'flex'}}>
                                    <div className='container-33'>
                                        <Select label={"Comercial"} value={state.commercial}
                                                onChange={(value) => handleChangeByName("commercial", value)}
                                                data={comerciales.map((commercial) => ({
                                                    value: commercial.code,
                                                    label: commercial.code === "CAR" ? "CARGONET" : commercial.name
                                                }))}
                                                placeholder={"-- seleccione un comercial --"}
                                                onBlur={() => setInvalidFields((prevState) => ({
                                                    ...prevState,
                                                    commercial: NEW_QUOTATION_VALIDATOR.commercial
                                                }))}
                                                error={invalidFields.commercial.message}
                                                withAsterisk
                                        />
                                    </div>
                                    <div className='container-33'>
                                        <OrganizationAsyncSelect
                                            label={"Cliente"}
                                            defaultValue={selectedClient?.nombreFantasia}
                                            setOrganization={handleClientChange}
                                            runOnBlur={() => setInvalidFields((prevState) => ({
                                                ...prevState,
                                                client: NEW_QUOTATION_VALIDATOR.client
                                            }))}
                                            error={invalidFields.client.isInvalid ? invalidFields.client.message : null}
                                            withAsterisk
                                        />
                                    </div>
                                </div>
                            )}
                            <div className='container-33'>
                                <Select label={"Transporte"} value={state.transportMode}
                                        onChange={(value) => handleTransportModeChange("transportMode", value)}
                                        data={[
                                            {value: "SEA", label: "Maritimo"},
                                            {value: "AIR", label: "Aéreo"},
                                            {value: "ROAD", label: "Terrestre"}
                                        ]}
                                        placeholder={"-- seleccione un transporte --"}
                                        onBlur={() => setInvalidFields((prevState) => ({
                                            ...prevState,
                                            transportMode: NEW_QUOTATION_VALIDATOR.transportMode
                                        }))}
                                        error={invalidFields.transportMode.isInvalid ? invalidFields.transportMode.message : null}
                                        withAsterisk
                                />
                            </div>
                            <div className='container-33'>
                                <Select
                                    label={"Modo"}
                                    value={state.modo}
                                    onChange={(value) => handleModoChange(value)}
                                    data={modeOptions}
                                    placeholder={"-- seleccione un modo --"}
                                    onBlur={() => setInvalidFields((prevState) => ({
                                        ...prevState,
                                        mode: NEW_QUOTATION_VALIDATOR.mode
                                    }))}
                                    error={invalidFields.mode.isInvalid ? invalidFields.mode.message : null}
                                    withAsterisk
                                />
                            </div>
                            {state.modo === 'LCL' &&
                                <div className='container-16'>
                                    <Select label={"Apilable"} value={state.stackable}
                                            onChange={(value) => handleChangeByName('stackable', value)}
                                            data={STACKABLE_OPTIONS}
                                    />
                                </div>
                            }
                            {containerVisible ?
                                <div className='container-16 d-flex flex-column flex-wrap gap-2 align-items-end'>
                                    <div className={"d-flex flew-row w-100 gap-2"}>
                                        <TextInput
                                            style={{width: "70%"}}
                                            label={state.modo === 'FCL' ? 'Contenedores' : 'Camiones'}
                                            value={state.containerQty}
                                            onBlur={() => setInvalidFields((prevState) => ({
                                                ...prevState,
                                                containerData: NEW_QUOTATION_VALIDATOR.containerData
                                            }))}
                                            error={invalidFields.containerData.isInvalid}
                                            withAsterisk
                                            disabled
                                        />
                                        <div className={"d-flex justify-content-center align-items-end"}>
                                            <ContainerTable updateStateValues={stateValues}
                                                            containers={state.containerData}
                                                            type={state.modo}/>
                                        </div>
                                    </div>
                                    {invalidFields.containerData.isInvalid &&
                                        <Text fs-variant={"xxs"} fw={400} w={"100%"}
                                              cVariant={"error"}>{invalidFields.containerData.message}</Text>
                                    }
                                </div>
                                :
                                <div className={"container-16"}/>
                            }
                            <div className='container-33 d-flex gap-2 align-items-start'>
                                <UnlocoAsyncInput
                                    label={"Origen *"}
                                    unloco={selectedOriginUnloco}
                                    setUnloco={setSelectedOriginUnloco}
                                    onBlur={() => setInvalidFields((prevState) => ({
                                        ...prevState,
                                        origin: NEW_QUOTATION_VALIDATOR.origin
                                    }))}
                                    error={invalidFields.origin.isInvalid}
                                    errorMsg={invalidFields.origin.message ?? null}
                                />
                            </div>

                            <div className='container-33 d-flex gap-2 align-items-start'>
                                <UnlocoAsyncInput
                                    label={"Destino *"}
                                    unloco={selectedDestinationUnloco}
                                    setUnloco={setSelectedDestinationUnloco}
                                    onBlur={() => setInvalidFields((prevState) => ({
                                        ...prevState,
                                        destination: NEW_QUOTATION_VALIDATOR.destination
                                    }))}
                                    error={invalidFields.destination.isInvalid}
                                    errorMsg={invalidFields.destination.message ?? null}
                                />
                            </div>
                            <div className='container-16 d-flex flex-row gap-2 align-items-start'>
                                <Select
                                    label={"Incoterm"} value={state.incoterm}
                                    onChange={(value) => handleIncotermChange(value)}
                                    data={INCOTERM_OPTIONS}
                                    placeholder={"-- seleccione --"}
                                    onBlur={() => setInvalidFields((prevState) => ({
                                        ...prevState,
                                        incoterm: NEW_QUOTATION_VALIDATOR.incoterm
                                    }))}
                                    error={invalidFields.incoterm.isInvalid ? invalidFields.incoterm.message : null}
                                    withAsterisk
                                />
                            </div>
                            {pickupVisible ?
                                <div className='container-16 d-flex flex-column flex-wrap gap-2 align-items-start'>
                                    <div className={"d-flex flew-row w-100 gap-2"}>
                                        <TextInput
                                            style={{width: "70%"}}
                                            label={'Pickup'}
                                            value={state.pickupData?.length} disabled
                                            onBlur={() => setInvalidFields((prevState) => ({
                                                ...prevState,
                                                pickupData: NEW_QUOTATION_VALIDATOR.pickupData
                                            }))}
                                            error={invalidFields.pickupData.isInvalid}
                                            withAsterisk={state.incoterm === "EXW"}
                                        />
                                        <div className={"d-flex justify-content-center align-items-end"}>
                                            <PickupTable setData={handlePickupChange} data={state.pickupData}/>
                                        </div>
                                    </div>
                                    {invalidFields.pickupData.isInvalid &&
                                        <Text fs-variant={"xxs"} fw={400} w={"100%"}
                                              cVariant={"error"}>{invalidFields.pickupData.message}</Text>
                                    }
                                </div>
                                :
                                <div className={"container-16"}/>
                            }
                            <div className="container-20">
                                <TextInput label="Referencia de Cliente"
                                           value={selectedClient?.reference}
                                           onChange={(e) => handleClientChangeByName("reference", e.target.value)}
                                />
                            </div>
                            <div className='container-20 d-flex flex-row gap-2 align-items-end'>
                                <TextInput label={"Valor de Mercaderia"} value={state.valorMercaderia}
                                           name={"valorMercaderia"} onChange={handleChangeNumeric}
                                           style={{width: "75%"}}/>
                                <Select value={state.monedaMercaderia}
                                        onChange={(value) => handleChangeByName("monedaMercaderia", value)}
                                        data={CURRENCIES_OPTIONS}
                                        comboboxProps={{width: rem(75)}}
                                        style={{width: "35%"}}/>
                            </div>
                            <div className='container-20'>
                                <Select label={"Seguro"} value={insurance ? "YES" : "NO"}
                                        onChange={(value) => handleInsuranceChange(value)}
                                        data={[
                                            {value: "NO", label: "NO"},
                                            {value: "YES", label: "SI"}
                                        ]}
                                        defaultValue={"NO"}
                                />
                            </div>
                            <div className='container-20 d-flex flex-row gap-2'>
                                <CommodityAsyncInput
                                    commodity={selectedCommodity}
                                    setCommodity={setSelectedCommodity}
                                    onBlur={() => setInvalidFields((prevState) => ({
                                        ...prevState,
                                        commodity: NEW_QUOTATION_VALIDATOR.commodity
                                    }))}
                                    error={invalidFields.commodity.isInvalid}
                                    errorMsg={invalidFields.commodity.message ?? null}
                                />
                            </div>
                            <div className='container-20'>
                                <DatePickerInput
                                    label={"Fecha de Embarque"}
                                    value={state.shipmentDate}
                                    onChange={(value) => handleChangeByName("shipmentDate", value)}
                                />
                            </div>
                            <div className='container-20 d-flex flex-row gap-2'>
                                <TextInput label={"Peso Total"}
                                           value={state.pesoTotal}
                                           disabled={!editableKgAndCbm}
                                           name={"pesoTotal"}
                                           onChange={handleChangeNumeric}
                                           rightSectionText={"KG"}
                                           onBlur={() => setInvalidFields((prevState) => ({
                                               ...prevState,
                                               pesoTotal: NEW_QUOTATION_VALIDATOR.pesoTotal
                                           }))}
                                           error={invalidFields.pesoTotal.message}
                                           withAsterisk
                                />
                            </div>
                            <div className='container-20 d-flex flex-row gap-2'>
                                <TextInput label={"Volumen Total"}
                                           value={state.cbmTotal}
                                           disabled={!editableKgAndCbm}
                                           name={"cbmTotal"}
                                           onChange={handleChangeNumeric}
                                           rightSectionText={"M3"}
                                           onBlur={() => setInvalidFields((prevState) => ({
                                               ...prevState,
                                               cbmTotal: NEW_QUOTATION_VALIDATOR.cbmTotal
                                           }))}
                                           error={invalidFields.cbmTotal.message}
                                           withAsterisk
                                />
                            </div>
                            <div className='container-20 d-flex flex-row gap-2'>
                                <TextInput label={"Chargeable Total"} value={state.chargeableTotal} disabled
                                           name={"chargeableTotal"}
                                           rightSectionText={"KG"}
                                />
                            </div>
                            <div className='container-20 d-flex flex-row gap-2'>
                                <TextInput label={"Total PLT"} value={state.pltTotal}
                                           disabled={!editableKgAndCbm}
                                           name={"pltTotal"} onChange={handleChangeNumeric}
                                           rightSectionText={"PLT"}
                                />
                            </div>
                            <div className='container-20 d-flex flex-row gap-2'>
                                <TextInput label={"Total CTN"} value={state.ctnTotal}
                                           disabled={!editableKgAndCbm}
                                           name={"ctnTotal"} onChange={handleChangeNumeric}
                                           rightSectionText={"CTN"}
                                />
                            </div>
                        </div>
                    </Container>
                    {/* packing section */}
                    <Container direction={"column"} p={8} rowGap={8}>
                        <PackingTable quotation={state} updateStateValues={stateValues}/>
                    </Container>
                </Container>
                {/* right section */}
                <Container styleVariant={"unstyled"} w={"40%"} direction={"column"} rowGap={8}>
                    <Container direction={"column"} p={16}>
                        <Title order={4} cVariant={"primary"} w={"100%"}
                               style={{textAlign: "center"}}>PROVEEDORES</Title>
                        {availableProviders?.length > 0 && necessaryDataForProvider() ?
                            <ProvidersTable transportMode={state.transportMode} mode={state.modo}
                                            providers={availableProviders} origin={state.origenCodigo}
                                            setQuotationContacts={setQuotationContacts}/>
                            :
                            <Container styleVariant={"unstyled"} bg-variant={"surface-1"}
                                       justify="center"
                                       align="center"
                                       direction="column"
                                       rowGap={16}
                                       style={{height: 205}}>
                                {!necessaryDataForProvider() ?
                                    <>
                                        <FontAwesomeIcon icon={faHourglassHalf} size={"xl"}/>
                                        <Text>Debe de ingresar Transporte, Modo, Origen y Destino para obtener
                                            proveedores</Text>
                                    </>
                                    :
                                    <>
                                        <FontAwesomeIcon icon={faCircleXmark} size={"xl"}/>
                                        <Text>No hay proveedores disponibles</Text>
                                    </>
                                }
                            </Container>
                        }
                    </Container>
                    {/* mail section */}
                    <Container direction={"column"} p={8}>
                        <Title order={4} cVariant={"primary"} style={{textAlign: "center"}}>MAIL</Title>
                        <Container className={"mb-2"} styleVariant={"unstyled"}>
                            <Checkbox
                                label={"Copiar a comercial"}
                                checked={notifyCommercial}
                                onChange={() => setNotifyCommercial(!notifyCommercial)}
                            />
                        </Container>
                        <div className={"mb-2 d-flex flex-row gap-2 align-items-end"}>
                            <TextInput name={"mailSubject"} value={mailSubject} size={"xs"}
                                       onChange={(e) => setMailSubject(e.target.value)}
                                       style={{width: "90%"}}/>
                            <Select name={"mailIdioma"} value={mailIdioma}
                                    onChange={(value) => setMailIdioma(value)}
                                    data={[
                                        {value: "es", label: "ES"},
                                        {value: "en", label: "EN"},
                                        {value: "pt", label: "PT"}
                                    ]}
                                    size={"xs"}
                                    style={{width: "12.5%"}}/>
                        </div>
                        <RichTextEditor defaultContent={defaultMail} content={mail} setContent={setMail}/>
                    </Container>
                    {/* bottom buttons section */}
                    <Container styleVariant={"unstyled"} justify={"center"} direction={"row"} columnGap={8} pt={8}>
                        <Indicator w={"25%"} inline processing color="green" disabled={!noteExistsAndIsNotEmpty(state?.notes, NOTES_TYPES.ON_REQUEST)}>
                            <Button w={"100%"} bgVariant={"tertiary"} onClick={() => setShowWorkNotes(true)}>
                                Notas
                            </Button>
                        </Indicator>
                        <Indicator w={"25%"} inline processing color="green" disabled={state?.docs?.length === 0}>
                            <Button w={"100%"} bgVariant={"tertiary"} onClick={() => setShowUploadDocs(true)}>
                                Documentos
                            </Button>
                        </Indicator>
                        <Container styleVariant={"unstyled"} justify={"center"} w={"25%"}>
                            <Button w={"100%"} bgVariant={"secondary"} onClick={() => setShowSaveDraftModal(true)}>
                                Guardar
                            </Button>
                        </Container>
                        <Container styleVariant={"unstyled"} justify={"center"} w={"25%"}>
                            <Menu shadow="md" zIndex={99999999}>
                                <MenuTarget>
                                    <Button w={"100%"}>
                                        Enviar Solicitud
                                    </Button>
                                </MenuTarget>

                                <MenuDropdown>
                                    <MenuItem onClick={handleAddDirectCosts}>
                                        Agregar costo directo
                                    </MenuItem>
                                    <MenuItem onClick={
                                        _.debounce(() => {
                                            modals.openContextModal({
                                                modal: "confirm",
                                                title: "¿Enviar solicitud de cotización?",
                                                innerProps: {
                                                    onConfirm: () => sendQuotation(true),
                                                }
                                            });
                                        }, 1000, {leading: true, trailing: false})
                                    }>
                                        Enviar solicitud por mail
                                    </MenuItem>
                                </MenuDropdown>
                            </Menu>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </>
    );
}

export default QuotationAgents;
